import styled, { css } from "styled-components";
import img from "../../../../static/topimage.png";

export const $FeatureImage = styled.div`
  height: 25.5em;
  float: left;
  width: 100%;
  margin: 0px 30px 20px 0;
  position: relative;

  @media only screen and (max-width: 768px) {
    height: 23em;
  }

  .feature-image {
    height: 100%;
    width: 100%;
    background-image: url(${img});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .Overlay {
    background-color: #000;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    opacity: 0.55;
  }

  .line-curve {
    position: absolute;
    bottom: 0;
    width: 120em;
    right: 2em;
    @media only screen and (max-width: 768px) {
      display: none;
    }
    div {
      svg {
        height: auto;
        width: 100%;
      }
    }
  }

  .block-text {
    left: 2em;
    top: 70%;
    position: absolute;
    transform: translatey(-50%);
    width: auto;
    text-align: left;
    line-height: 1.1;

    span {
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
      strong {
        font-weight: bold;

        &.color1 {
          color: #4fbec7;
        }

        &.color2 {
          color: #c6c287;
        }
      }

      :nth-child(1) {
        font-size: 4em;
      }
    }
    .button {
      text-decoration: none;
      color: #fff;
      cursor: pointer;
      display: block;
      font-size: 1em;
      width: 14em;
      text-align: center;
      background: transparent
        linear-gradient(105deg, var(--unnamed-color-eb5536) 0%, #ff886f 100%) 0%
        0% no-repeat padding-box;
      background: transparent linear-gradient(105deg, #eb5536 0%, #ff886f 100%)
        0% 0% no-repeat padding-box;
      border-radius: 50px;
      padding: 0.8em 1.6em;
      margin-top: 1em;
      @media only screen and (max-width: 768px) {
        margin: 1em auto;
      }
    }
  }
  .corner {
    div {
      position: absolute;
      bottom: 0;
      width: 46em;
      height: 100;
      left: 0;
      svg {
        height: 100%;
        width: 100%;
        opacity: 0.6;
      }
    }
  }
`;
