import styled, { css } from "styled-components";
import imageManCircleConstruction from "../../../../static/image-nos-usine.png";
export const $NosUsines = styled.div`
  width: 100%;
  /* height: 50em; */
  .content {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 2.5em;
    &:before {
      content: "";
      border-radius: 50em;
      padding: 1em;
      display: inline-block;
      background: url(${imageManCircleConstruction}) no-repeat;
      background-size: contain;
      transform: translateX(-5em);
      width: 40em;
      max-width: 50%;
      height: 40em;
      border: 4px solid green;
      z-index: -1;

      position: absolute;

      @media only screen and (max-width: 768px) {
        transform: translate(-5em, -5em);
        width: 40em;
        max-width: 30em;
        max-height: 30em;
      }
    }

    .inner-text {
      text-align: right;
      font-weight: 600;

      @media only screen and (max-width: 768px) {
        justify-content: left;
        display: flex;
        flex-direction: column;
      }

      h3 {
        display: flex;
        width: 240px;
        margin: 0 0 0 auto;
        /* font-size: 40px; */
        position: relative;
        text-transform: uppercase;
        font-weight: bold;
        @media only screen and (max-width: 768px) {
          width: auto;
        }
        /* @media only screen and (max-width: 768px) {
          font-size: 35px;
        }
        @media only screen and (max-width: 425px) {
          font-size: 30px;
        } */

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 106px;
          height: 106px;
          background-color: fff;
          border: solid 1px #118738;
          border-radius: 50em;
          transform: translate(-40%, -35%);
        }
      }
      p {
        font-size: 16px;
        width: 55%;
        margin: 3em 0 3em auto;
        border-radius: 14px;

        @media only screen and (max-width: 1024px) {
          padding: 0.5em;
          width: 100%;
          background-color: rgba(255, 255, 255, 0.8);
        }
      }
    }

    .list-content {
      .list {
        background: transparent;
        margin: 0 0 0 auto;
        width: 100%;
        max-width: 1000px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .city {
          width: 100%;
          padding: 6px;

          @media only screen and (min-width: 576px) {
            width: 50%;
          }

          span {
            /* &:hover {
              cursor: pointer;
            } */

            text-transform: uppercase;
            text-decoration: none;
            color: #fff;
            font-weight: 700;
            font-size: 14px;
            display: flex;
            width: 100%;
            padding: 1.5em 2.4em;
            background: transparent
              linear-gradient(
                100deg,
                var(--unnamed-color-eb5536) 0%,
                #ff886f 100%
              )
              0% 0% no-repeat padding-box;
            background: transparent
              linear-gradient(100deg, #eb5536 0%, #ff886f 100%) 0% 0% no-repeat
              padding-box;

            @media only screen and (max-width: 575px) {
              justify-content: center;
            }
          }
        }
      }
    }
  }
`;
