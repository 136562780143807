import styled, { css } from "styled-components";
import backgroundImg from "../../../../static/foret-verte.jpg";
export const $FlexiJobs = styled.div`
  width: 100%;
  height: auto;
  background: url(${backgroundImg}) no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .container {
    margin: 8em 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (min-width: 769px) {
      width: 53.6em;
    }

    .titleh3 {
      position: relative;
      text-transform: uppercase;
      /* font-size: 40px; */
      font-weight: bold;
      color: #fff;
      /* @media only screen and (max-width: 768px) {
        font-size: 35px;
      }
      @media only screen and (max-width: 425px) {
        font-size: 30px;
      } */

      .color1 {
        position: relative;
        color: #4fbec7;
      }

      .color2 {
        color: #c6c287;
      }

      .circle-around-H {
        position: absolute;
        left: 0;
        top: 0;
        width: 2.65em;
        height: 2.65em;
        background-color: fff;
        border: solid 1px #00c5d1;
        border-radius: 50em;
        transform: translate(-36%, -41%);
      }
    }
    .paragraph {
      font-size: 16px;
      color: #fff;
      margin: 1.5em 0;
    }
    .button {
      font-size: 20px;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      padding: 0.8em 2.4em;
      margin: 1em;
      background: transparent
        linear-gradient(100deg, var(--unnamed-color-eb5536) 0%, #ff886f 100%) 0%
        0% no-repeat padding-box;
      background: transparent linear-gradient(100deg, #eb5536 0%, #ff886f 100%)
        0% 0% no-repeat padding-box;

      @media only screen and (max-width: 768px) {
        font-size: 16px;
      }
      .flexi-jobs {
        line-height: 1;
        padding: 0 0.2em;

        div {
          svg {
            width: 9em;
          }
        }
      }

      span {
        color: #fff;
        line-height: 1;
        font-weight: bold;
        text-transform: uppercase;
        padding: 0 0.2em;
      }
    }
  }
  .tronc-3 {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translate(-50%, 50%);
    div {
      svg {
        width: 90vw;
        height: 29em;
      }
    }
  }
`;
