import PropTypes from "prop-types";
import React from "react";
import { $NosUsines } from "./SC_NosUsines.js";
import NosUsine from "../../../client/data/nosUsine.js";

const NosUsines = () => {
  return (
    <$NosUsines>
      <div className="content">
        <div className="inner-text">
          <h3>Nos usines</h3>
          <p>Voici l’ensemble de nos usines du Saguenay-Lac-Saint-Jean</p>
        </div>
        <div className="list-content">
          <ul className="list">
            {NosUsine.map(elements => (
              <li className="city">
                {/* {elements.ville} */}
                {/* href={`/usine/${elements.sousPageIndex}`} */}
                <span>{elements.ville}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </$NosUsines>
  );
};

export default NosUsines;
