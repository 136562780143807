import styled from "styled-components";
import imgFemmeCercle from "../../../../static/femme-cercle.png";
export const $Avantages = styled.div`
  padding: 5em 16%;
  margin: 0 auto;
  /* height: 57.3em; */
  width: 100%;
  position: relative;
  /* overflow: hidden; */

  &:after {
    z-index: -1;
    content: "";
    background: url("../../../../SVG/tronc-s.svg") no-repeat center;
    background-size: 100%;
    width: 65%;
    height: 100%;
    position: absolute;
    bottom: -30%;
    left: 0;
  }

  @media only screen and (max-width: 1024px) {
    padding: 5em 5%;
    height: auto;
  }

  .femme-cercle {
    &:before {
      content: "";
      display: inline-block;
      background: url(${imgFemmeCercle}) no-repeat;
      background-size: cover;
      transform: translatex(15%);
      width: 100%;
      height: 100%;
    }

    position: absolute;
    display: block;
    right: 0;
    top: 44%;
    width: 47em;
    height: 47em;
    z-index: -1;

    overflow: hidden;

    @media only screen and (max-width: 1024px) {
      /* top: 10%; */

      font-size: 1.3vw;
    }
    @media only screen and (min-width: 769px) {
      transform: translateY(-50%);
    }

    @media only screen and (max-width: 768px) {
      top: 1%;

      font-size: 1.3vw;
    }
  }

  div {
    h3 {
      /* font-size: 40px; */
      position: relative;
      text-transform: uppercase;
      font-weight: bold;
      /* @media only screen and (max-width: 768px) {
        font-size: 35px;
      }
      @media only screen and (max-width: 425px) {
        font-size: 30px;
      } */

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 2.65em;
        height: 2.65em;
        background-color: fff;
        border: solid 1px #118738;
        border-radius: 50em;
        transform: translate(-50%, -35%);
      }
    }
    p {
      font-size: 16px;
      width: 55%;
      margin: 3em 0;
      border-radius: 14px;

      @media only screen and (max-width: 1024px) {
        padding: 0.5em;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.8);
      }
    }
    .container {
      padding: 0;
      max-width: 100%;
      position: relative;

      .row {
        .colon {
          padding: 0;
          position: relative;
          margin: -0.1em 0;
        }
      }
    }
  }

  .description-container {
    display: ${props => (props.openState ? "block" : "none")};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    background-color: #fff;
    box-shadow: 0px 0px 3px 3px rgba(000, 000, 000, 0.3);
    border-radius: 10px;

    .close {
      padding: 10px;
    }
  }
`;
export const $AvantagesUnite = styled.div`
  cursor: pointer;
  padding-top: 18.5%;
  background: ${props =>
    props.color1 && props.color2
      ? `transparent linear-gradient(90deg, ${props.color1} 0%, ${props.color2} 100%) 0% 0% no-repeat `
      : ""};

  @media only screen and (max-width: 768px) {
    padding-top: 25%;
  }

  &:hover {
    background: ${props => (props.color1 ? props.color1 : "")};
  }

  .inner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0px 10px 20px #2e334715;
    height: 100%;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 600;
    font-size: 1.25em;
    width: 100%;
    justify-content: space-between;

    /* @media only screen and (max-width: 768px) {
      flex-direction: column;
    } */

    .icon-advantage {
      display: flex;
      align-items: center;
      padding: 1em;
      @media only screen and (max-width: 768px) {
        padding: 0 0 0 1.5em;
      }
      div {
        svg {
          height: auto;
          width: 2.2em;
          @media only screen and (max-width: 768px) {
            width: 3em;
          }
        }
      }
    }
    span {
      text-transform: uppercase;
      width: 60%;
      font-weight: 700;
      line-height: 1.2;
      font-size: 14px;
    }
    .more-description-icon {
      padding: 0 0.5em;
      @media only screen and (max-width: 768px) {
        padding: 0.5em;
      }
      div {
        svg {
          height: 2em;
          width: auto;

          @media only screen and (max-width: 768px) {
            height: 3em;
            width: auto;
            margin: 0 2em 0 0;
          }
        }
      }
    }
  }
`;
