import PropTypes from "prop-types";
import React from "react";
import { $FeatureImage } from "./SC_FeatureImage";
import { ReactSVG } from "react-svg";
import imgCircle from "../../../../static/femme-cercle2x.png";

const FeatureImage = React.memo(({ headerImageSrc, allHeaderImageSrc }) => {
  return (
    <$FeatureImage backGroundImage={allHeaderImageSrc}>
      <div className="feature-image"></div>
      <div className="Overlay"></div>
      {/* <ReactSVG
        className="LineCurve"
        src="../../../SVG/ligne-courbe-machinerie.png"
      ></ReactSVG> */}
      {/* <img
        className="line-curve"
        src="../../../ligne-courbe-machinerie@2x.png"
      /> */}
      <ReactSVG className="corner" src={`../../../SVG/coin-1.svg`}></ReactSVG>
      <div className="block-text">
        <span className="">
          Des <strong className="color1">emplois</strong>
          <br /> pour <strong className="color2">grandir.</strong>
        </span>
        {/* <a className="button" href="#offer">
          Voir les offres
        </a> */}
      </div>
    </$FeatureImage>
  );
});

FeatureImage.propTypes = {
  headerImageSrc: PropTypes.string
};

FeatureImage.defaultProps = {
  headerImageSrc: ``
};

export default FeatureImage;
