import PropTypes from "prop-types";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import OfferCard from "./OfferCard";

import "./jobList.scss";

const getOfferItemsBlock = offerList => {
  if (offerList === undefined || offerList === null || offerList.length === 0)
    return (
      <p className="textAlignCenter">
        Aucune(s) offre(s) disponible(s)/trouvée(s) en ce moment.
      </p>
    );
  const offerListHtmlContent = offerList.map((offer, index) => {
    return (
      <Col
        xl="3"
        md="4"
        sm="6"
        xs="12"
        style={{ padding: "0", cursor: "auto" }}
      >
        <OfferCard offerData={offer} key={index} />
      </Col>
    );
  });

  return (
    <Row id="jobList" className="job-list">
      {offerListHtmlContent}
    </Row>
  );
};

function OfferList(props) {
  return <Container fluid>{getOfferItemsBlock(props.offerList)}</Container>;
}

OfferList.propTypes = {
  list: PropTypes.array
};

OfferList.defaultProps = {
  list: []
};

export default OfferList;
