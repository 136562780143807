import styled from "styled-components";

export const $Body = styled.div`
  display: flex;
  flex-direction: column;
  .offer-list {
    margin: 0 2em;

    @media only screen and (max-width: 575px) {
      margin: 0;
    }
    .sub-title {
      margin: 1.5em;
      display: flex;
      font-size: 2.35em;
      text-align: center;
      font-weight: 600;
      flex-direction: column;
      align-items: center;
      .sub-t {
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;
        position: relative;
        margin: 0 auto;

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 3em;
          height: 3em;
          border: solid 1px #118738;
          border-radius: 50em;
          transform: translate(-42%, -50%);
        }
      }
      .paragraph {
        margin: 2em 0 0;
        width: 75%;
        font-size: 16px;
        @media only screen and (max-width: 768px) {
          width: 95%;
          font-size: 14px;
        }
      }
    }
  }
`;
