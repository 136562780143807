import React from "react";
import SEO from "../components/seo";
import BaseLayout from "../components/BaseLayout";
import FeatureImage from "../components/body/featureImage/FeatureImage";
import OfferList from "../components/dependentComponent/offerList/OfferList";
import "../components/styles/layout.scss";
// import "bootstrap/dist/css/bootstrap.min.css"
import clientData from "../client/data/data.json";
import GetRandomInt from "../components/dependentComponent/functions/getRandomNumber";
import { $Body } from "../styles/SC_Body";
import FlexiJobs from "../components/body/flexiJobs/FlexiJobs";
import Avantages from "../components/body/avantage/Avantages";
import "swiper/swiper.scss";
import NosUsines from "../components/body/nosUsines/NosUsines";
import { Helmet } from "react-helmet";

function HomePage({ pageContext: { fechedOfferList } }) {
  const [initialOfferList, setInitialOfferList] = React.useState(
    fechedOfferList
  );
  const [offerList, setOfferList] = React.useState(fechedOfferList);
  const [imgUrl, setImgUrl] = React.useState("");
  const headerImageHomePage = () => {
    if (imgUrl === "") {
      let imageNumber = GetRandomInt(2);
      let imageUrl = `/${clientData.featureImage[imageNumber]}`;
      setImgUrl(imageUrl);
      return imageUrl;
    } else {
      return imgUrl;
    }
  };

  return (
    <>
      <BaseLayout>
        {/* <Helmet>
          <script
            async="async"
            src="https://static.mobilemonkey.com/js/109289714535183.js"
          ></script>{" "}
           <script> </script>
        </Helmet> */}
        <SEO description="Resolu" title="Resolu" />

        {/* <OfferSearch
          initialOfferList={initialOfferList}
          setOfferList={setOfferList}
        /> */}
        <$Body>
          <FeatureImage
            headerImageSrc={headerImageHomePage()}
            allHeaderImageSrc={clientData.featureImage}
          />
          <div className="offer-list">
            <div className="sub-title">
              <div className="sub-t">
                {/* <div id="offer" className="circle-around-n"></div>Nos */}
                <h3></h3>opportunités d'emploi
              </div>
              <div className="paragraph">
                Chez Résolu, nous offrons un milieu de travail valorisant et
                sécuritaire, riche en occasions et en défis.
                <br />
                Sélectionnez l’établissement de votre choix.
              </div>
            </div>
            <OfferList offerList={offerList} />
          </div>
          {/* <FlexiJobs /> */}
          {/* <Avantages /> */}
          <NosUsines />
          {/* <SwiperVideo /> */}
        </$Body>
      </BaseLayout>
    </>
  );
}

export default HomePage;
